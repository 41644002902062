import '../App.css';
import { useStyles } from '../theme';
import {AppBar, Toolbar, Typography} from '@material-ui/core';

interface MainAppBarProps {title: String}

export default function MainAppBar(props: MainAppBarProps) {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.appBar}>
          {props.title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}