import React from 'react';
import '../App.css';
import {Link, Typography} from '@material-ui/core';

export default function Copyright() {
  return (
    <Typography variant="body2" color='textSecondary' align="center">
      {'Copyright © '}
      <Link color="inherit" href="https:/adastraperamorem.no/">
        Ad Astra Per Amorem
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}