import React from 'react';
import '../App.css';
import { useStyles } from '../theme';
import {Divider, Drawer, List, ListItem, ListItemText, ListItemIcon, Toolbar} from '@material-ui/core';
import Copyright from './Copyright';
import { HashLink } from "react-router-hash-link";

export interface ListLinkProps {icon?: string, text: string, secondary?: boolean, to: string}

export const ListItemLink = (props: ListLinkProps) => (
  <ListItem button component={HashLink} to={props.to}>
    {props.icon ? <ListItemIcon>{props.icon}</ListItemIcon> : null}
    <ListItemText
    primary={props.secondary ? '• ' + props.text : props.text}
    primaryTypographyProps={props.secondary
      ? {variant: "body2"}
      : {variant: "body1"}
    }/>
  </ListItem>
);

export default function NavDrawer() {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
    <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          <ListItemLink text='Hjem' to='/'/>
        </List>
        <Divider />
        <List>
          <ListItemLink text='Bryllup' to='/bryllup'/>
          <ListItemLink text='Praktisk' to='/praktisk'/>
          <ListItemLink text='Ønskelister' to='/onskeliste'/>
        </List>
        <div className={classes.footer}>
          <Copyright/>
        </div>
      </div>
    </Drawer>
  );
}
  