import '../App.css';
import { useStyles } from '../theme'
import { WeddingBasePage } from '../components/WeddingBasePage';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import React from 'react';

interface WishProps {
  id: string,
  title: string,
  total: number,
  bought: number,
  boughtSelf: number,
  received: number,
  cost: number,
}
type DataRow = [string, number, number, number, number, number];
const data: Record<string, DataRow|null> = {
  "Middagsservice": null,
  "1128040325510263": ["Dekketallerken 33cm",               12,  1,  5,  6, 1649],
  "1128040325510324": ["Dyp tallerken 24cm",                12, 10,  2,  6, 409],
  "1128040325510229": ["Middagstallerken 28cm",             12,  9,  3, 12, 569],
  "1128040325510216": ["Asjett 16cm",                       12,  0,  6,  5, 409],
  "1128040325511020": ["Suppeterin 3L",                      1,  1,  0,  1, 4329],
  "1128040325511620": ["Sausnebb 0,55L",                     1,  1,  0,  0, 1229],
  "1128040325511320": ["Lokkefat 1,6L",                      2,  1,  0,  2, 3629],
  "1128040325513326": ["Skål 26cm 2,8L",                     2,  2,  0,  2, 1939],
  "1128040325513319": ["Skål 19cm 1,7L",                     2,  1,  0,  2, 1349],
  "1128040325512738": ["Serveringsfat 38cm",                 1,  1,  0,  1, 1559],
  "1128040325512734": ["Serveringsfat 34cm",                 1,  1,  0,  1, 1299],
  "1128040325512725": ["Serveringsfat 25cm",                 1,  1,  0,  1, 1039],
  "1128040325512718": ["Serveringsfat 18cm",                 2,  0,  2,  2, 909],
  "Kaffeservice": null,
  "1128040325515394": ["Skål 15cm 0,87L",                    2,  2,  0,  1, 619],
  "1128040325510222": ["Tallerken 22cm",                    12, 11,  1, 11, 339],
  "1128040325514772": ["Kombikopp 0,3L",                    12,  0,  6,  0, 349],
  "1128040325514642": ["Tekopp 0,24L",                       6,  1,  5,  6, 349],
  "1128040325514641": ["Underskål til kombikopp/tekopp",    18,  1, 11,  6, 409],
  "1128040325514717": ["Espressokopp 0,12L",                 6,  0,  0,  0, 319],
  "1128040325514716": ["Underskål til espressokopp 11,5 cm", 6,  0,  0,  0, 259],
  "1128040325514030": ["Kaffekanne 1,5L",                    1,  1,  0,  1, 2329],
  "1128040325514237": ["Tekanne m/tesil 1,35L",              1,  0,  1,  1, 3799],
  "1128040325514430": ["Fløtemugge 0,25L",                   2,  0,  1,  2, 739],
  "1128040325514330": ["Sukkerskål 0,22L",                   2,  0,  1,  0, 759],
  "1128040325513335": ["Stor bolle 35cm 4L",                 0,  0,  0,  0, 4039],
  "Asia skin gold": null,
  "14613-403239-15383": ["Skål 15cm",                       12,  0,  6,  0, 330],
  "14613-403239-15384": ["Skål 14cm",                        6,  0,  0,  0, 310],
  "14613-403255-13351": ["Skål 12cm",                       12,  0,  6,  6, 270],
  "14204-403255-19106": ["Porselenskjeer med mønster",       6,  0,  0,  6, 215],
  "14204-403255-15536": ["Holdere til spisepinner",          6,  0,  0,  0, 240],
};

var formatter = new Intl.NumberFormat('nb-NO', {
  style: 'currency',
  currency: 'NOK',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const wishes: (WishProps)[] = Object.keys(data).map<WishProps>((id) => {
  let row = data[id];
  if (row == null) {
    return ({
      id: id,
      title: id,
      total: -1,
      bought: -1,
      boughtSelf: -1,
      received: -1,
      cost: -1,
    });
  }
  let [name, total, bought, boughtSelf, received, cost] = row;
  return ({
    id: id,
    title: name,
    total: total,
    bought: bought,
    boughtSelf: boughtSelf,
    received: received,
    cost: cost,
  });
});

function WishRow(wish: WishProps) {
  const classes = useStyles();
  const image = require('../images/wedding/wishlist/' + wish.id + '.jpg');
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableRow key={wish.id} style={{opacity: wish.received >= wish.total ? '100%' : (wish.bought + wish.boughtSelf) >= wish.total ? '33%' : '100%', backgroundColor: wish.received >= wish.total ? '#F9E6CF' : (wish.bought + wish.boughtSelf) > wish.total ? 'red' : 'transparent'}}>
      <TableCell className={classes.wishTableCell} component="th" align="center">
        <Typography variant="body1">
          {wish.title}
        </Typography>
        <br/>
        {wish.id}
      </TableCell>
      <TableCell className={classes.wishTableCell} component="th" align="center">{wish.bought}+{wish.boughtSelf}/{wish.total}({wish.received})</TableCell>
      <TableCell className={classes.wishTableCell} component="th" align="center">{formatter.format(wish.cost)}</TableCell>
      <TableCell className={classes.wishTableCell} align="center">
        <Image src={image.default} aspectRatio={1} style={{cursor:"pointer"}} onClick={handleOpen}/>
        <Dialog
          fullScreen={fullScreen}
          maxWidth={'md'}
          fullWidth={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        ><DialogTitle id="responsive-dialog-title">{wish.title}</DialogTitle>
          <DialogContent dividers={true}>
            <Container style={{maxWidth: '80vh'}}>
              <Image src={image.default} disableTransition aspectRatio={1}/>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" autoFocus>
              Lukk
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}

export default function WishlistPage() {
  const classes = useStyles();
  return (
    <WeddingBasePage title='Gåveynskjer'>
      <Typography align="center" variant="h4" id="praktisk">Gåveynskjer</Typography>
      <Divider/>
      <Typography style={{padding: "8px 24px"}}>
        Me er utruleg glade og takksame for at så utruleg mange av dykk allereie har tatt
        kontakt og sagt at de vil kome i bryllupet vårt til hausten. Me set stor pris på
        at de vil ta dykk tid, og reise til Voss for å feire den store dagen med oss! 
        <br/><br/>
        At me set bryllupet til Voss gjer nemleg at me alle vert tilreisande. Me håper
        difor at de vil ta reisekostnader og kostnader knytta til overnatting m.m. med
        i betraktninga når det gjeld gåver. For oss er den størsta gåva at de vil kome
        og vere med oss på Voss denne oktoberhelga! 
        <br/><br/>
        Som dei fleste av dykk veit flytta me også inn i vår nye leilighet på Lilleby
        vinteren 2020, og me har allereie det meste av det me treng og har plass til her.
        I anledning bryllupet har me likevel funne eit finservise me liker godt hos C.I.
        Pedersen her i Trondheim. Serviset er produsert av Rosenthal GmbH og heiter
        "Gropius TAC Skin Gold". 
        <br/><br/>
        C.I. Pedersen har ikkje ein eigen nettbutikk, og har heller ikkje serviset
        liggande inne i den nettbutikken dei deler med fleire andre butikker. Me har difor
        laga ei oversikt der ein kan sjå bilete av dei ulike delene knytta saman med namn,
        varenummer og pris frå lista hos C.I. Pedersen. Trykk gjerne på bileta for å sjå
        dei litt større.
        <br/><br/>
        <b>Ved bestilling ynskjer C.I. Pedersen at ein ringer på telefonnummer:
        {' '}<a href="tel:+4773528850">+47 73 52 88 50</a> eller sender e-post til
        {' '}<a href="mailto:butikk@cipedersen.no">butikk@cipedersen.no</a>.</b><br/><br/>
        Dei vil oppdatere
        lista hos seg manuelt etter kvart som folk bestiller frå den, og me vil prøve å få
        med den same oppdateringa mht. antallet fortløpande. Til orientering vil me ikkje
        få noko oversikt over kven som har bestilt kva, kun antall av totalen på kva som er bestilt. 
        <br/><br/>
        Sidan me alle vert tilreisande til (og frå) Voss vil det mest praktiske for oss
        alle vere at de som ynskjer å kjøpe servise frå bryllupslista lar det stå hos C.I.
        Pedersen. Dette spesielt mtp. dykk som ikkje bur i nærleiken av Trondheim! Då kan
        me berre hente det der når me kjem heim igjen til Trondheim, og så slepp me alle å
        frakte på mykje knuseleg servise. Det er absolutt ikkje viktig for oss å ha eit
        "prangande" gåvebord i lokalet på Fleischer's Hotel, så skriv gjerne heller kva de
        har gitt oss i gåve i eit kort som kan stå der.
        <br/><br/>
        Det er også mogleg å kjøpe gåvekort hos dei andre butikkane i kjeden, t.d. Brødrene
        Pedersen i Stavangerområdet og Glass Thomsen i Bergen
        (<a href="https://www.designforevig.no/butikker/">de finn ein oversikt her</a>).
        Dersom de henviser til bryllupslista vår kan truleg butikkane også hjelpe med bestilling frå lista.
        <br/><br/>
        Men igjen vil me presisere at den største gåva er at de vil kome til Voss og vere
        med i bryllupet! De set me umåteleg stor pris på. 
        <br/><br/>

        <b>Her er lenka til lista hos C.I.Pedersen:{' '}<a href="https://www.designforevig.no/bryllupslister/260903">https://www.designforevig.no/bryllupslister/260903</a></b>
        <br/>
        <b>NB!</b> Tesila i stål som ligg inne i ynskjelista er ikkje noko me ynskjer oss,
        men ligg inne for å kunne vise bryllupslista på nettsida. Med andre ord kan de sjå
        bort frå denne.
        <br/><br/>
      </Typography>
      <TableContainer component={Paper} style={{maxHeight: "85vh"}}>
        <Table stickyHeader>
          <colgroup>
              <col style={{width:'30%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'20%'}}/>
          </colgroup>
          <TableHead>
            <TableRow className={classes.wishTableHead} style={{background: '#F9C262'}}>
              <TableCell align="center" className={classes.wishTableCell} style={{background: '#F9C262'}}>
                <Typography variant="h6">Produkt</Typography>
              </TableCell>
              <TableCell align="center" className={classes.wishTableCell} style={{background: '#F9C262'}}>
                <Typography variant="h6">Antall (mottatt)</Typography>
              </TableCell>
              <TableCell align="center" className={classes.wishTableCell} style={{background: '#F9C262'}}>
                <Typography variant="h6">Pris/stk</Typography>
              </TableCell>
              <TableCell align="center" className={classes.wishTableCell} style={{background: '#F9C262'}}>
                <Typography variant="h6">Bilde</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
          {wishes.map((row) => {
            if (row.total < 0) {
              return (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Typography variant="h6">{row.title}</Typography>
                  </TableCell>
                </TableRow>
              );
            }
            return WishRow(row);
          })}
        </TableBody>
        </Table>
      </TableContainer>
    </WeddingBasePage>
  );
}
