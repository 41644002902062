import React from 'react';
import '../App.css';
import { useStyles } from '../theme';
import MainAppBar from './MainAppBar';
import NavDrawer from './NavDrawer';
import {Box, Container, Toolbar} from '@material-ui/core';

export interface PageProps {title: string, children: React.ReactNode}

export function Page(props: PageProps) {
    const classes = useStyles();
    return (
      <Container>
        <Box my={4}>
          <div className={classes.root}>
            <MainAppBar title={props.title}/>
            <NavDrawer/>
            <main className={classes.content}>
              <Toolbar />
              {props.children}
            </main>
          </div>
        </Box>
      </Container>
    );
}
