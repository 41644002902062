import React from 'react';
import '../App.css';
import { useStyles } from '../theme';
import {ListItemLink} from './NavDrawer';
import { Button, Divider, Drawer, Hidden, List, ListItem, Typography } from '@material-ui/core';

function ContactUs() {
  return (
    <span>
      <Typography variant="body2" component={'span'}>
        Send gjerne epost:<br/>
        • <a href="mailto:olav.e.eiksund@outlook.com">olav.e.eiksund@outlook.com</a><br/>
        • <a href="mailto:olav.e.eiksund@outlook.com">kathrine.gangenes@outlook.com</a><br/>
        eller ring/send melding:<br/>
        • Olav Emil: <a href="tel:+4790913589">90913589</a><br/>
        • Kathrine: <a href="tel:+4795186435">95186435</a><br/>
        <br/>
        Kontakt kjøkemeister Ann Elin:<br/>
        E-post: <a href="mailto:annelinsolbu@gmail.com">annelinsolbu@gmail.com</a><br/>
        Telefon: <a href="tel:+4795975059">95975059</a><br/>
      </Typography>
    </span>
  );
};

export interface DrawerProps {
  mobileOpen: boolean,
  handleDrawerToggle: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void;
  }['bivarianceHack'],
}

export function WeddingNavDrawer(props: DrawerProps) {
  const classes = useStyles();
  const [showingEmail, setShowingEmail] = React.useState(false);
  let contactButton = showingEmail ? (
    <ContactUs />
  ) : (
    <Button variant="contained"
      onClick={() => setShowingEmail(true)}
    >
      <Typography variant="body2">
        Vis kontaktinformasjon
      </Typography>
    </Button>
  );
  const drawerContent = (
    <div className={classes.drawerContainer}>
      <List>
        <ListItemLink text='Bryllup' to='/bryllup#top'/>
        <ListItemLink text='Program' to='/bryllup#program'/>
        <ListItemLink text='Innslag til festen' to='/bryllup#innslag' secondary={true}/>
        <ListItemLink text='Praktisk informasjon' to='/bryllup#praktisk'/>
        <ListItemLink text='Gåveynskjer' to='/onskeliste'/>
        <Divider/>
        <ListItem>
          {contactButton}
        </ListItem>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor='left'
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor='left'
            open
          >
            {drawerContent}
          </Drawer>
        </Hidden>
    </nav>
  );
}