import PracticalInfoPage from './pages/PracticalInfoPage';
import WeddingPage from './pages/WeddingPage';
import WishlistPage from './pages/WishlistPage';
import { Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Route exact={true} path="/" component={WeddingPage}/>
      <Route path="/bryllup" component={WeddingPage}/>
      <Route path="/praktisk" component={PracticalInfoPage}/>
      <Route path="/onskeliste" component={WishlistPage}/>
    </div>
  );
}

export default App;
