import { red } from '@material-ui/core/colors';
import { createMuiTheme, makeStyles, responsiveFontSizes  } from '@material-ui/core/styles';

// A custom theme for this app
const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#FF6A00',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F2E8DE',
    },
  },
  typography: {
    h3: {
      fontFamily: "Dancing Script"
    },
    h4: {
      fontFamily: "Dancing Script"
    },
    h5: {
      fontFamily: "Dancing Script",
      lineHeight: 1.6,
    },
    h6: {
      fontFamily: "Dancing Script"
    },
    subtitle1: {
      fontSize: "1.25rem"
    },
    fontFamily: 'Kalam'
  },
  props: {
    MuiTooltip: {
      interactive: true,
      placement: 'bottom',
    }
  }
});

const theme = responsiveFontSizes(defaultTheme);

export default theme;

const drawerWidth = 240;

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: theme.spacing(3, 0, 6),
      display: 'flex',
      color: 'black',
    },
    content: {
      flexGrow: 1,
      margin: theme.spacing(0, 0, 6),
    },
    footer: {
      bottom: 0,
      position: 'absolute',
      flexDirection: 'row',
      alignContent: 'end',
    },
    appBar: {
      zIndex: 1.0,
    },
    drawer: {
      zIndex: 2.0,
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    toolbar: theme.mixins.toolbar,
    heart: {
      margin: '0px 0px -8px 0px',
      width: 48,
      [theme.breakpoints.only('sm')]: {
        margin: '0px 0px -7px 0px',
        width: 42,
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0px 0px -6px 0px',
        width: 32,
      },
      [theme.breakpoints.up('lg')]: {
        margin: '0px 0px -9px 0px',
        width: 52,
      },
    },
    wishTableHead: {
      backgroundColor: 'orange',
      padding: "8px 0px",
      boxShadow: theme.shadows[1]
    },
    wishTableCell: {
      padding: "8px 8px"
    },
    wishModalPaper: {
      position: 'absolute',
      width: '400px',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[8],
      padding: theme.spacing(2, 4, 3),
    }
  })
);