import React from 'react';
import '../App.css';
import { useStyles } from '../theme';
import { WeddingNavDrawer } from './WeddingNavDrawer';
import { PageProps } from './Page';
import { AppBar, Box, Container, Hidden, IconButton, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import orangeHeart from '../images/wedding/heart.svg';

export function WeddingBasePage(props: PageProps) {
    const classes = useStyles();    
    const [mobileOpen, setMobileOpen] = React.useState(false);
    
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
      <Container>
        <Box my={4}>
          <div className={classes.root}>
            <Hidden mdUp>
              <AppBar position="fixed" className={classes.appBar} elevation={0} color='transparent'>
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
            </Hidden>
            <WeddingNavDrawer
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
            <main className={classes.content}>
              <Typography align="center" variant="h3" id='top'>
                Kathrine <img src={orangeHeart} className={classes.heart} alt='🧡'/> Olav Emil
              </Typography>
              {props.children}
            </main>
          </div>
        </Box>
      </Container>
    );
}
