import { useEffect } from "react";
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import { useLocation } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import firebase from 'firebase/app';
import 'firebase/analytics';

var firebaseConfig = {
  apiKey: "AIzaSyABcoyQfy_WrdnGfOxshN6FnDqbnN2sc24",
  authDomain: "adastraperamorem.firebaseapp.com",
  projectId: "adastraperamorem",
  storageBucket: "adastraperamorem.appspot.com",
  messagingSenderId: "340983379768",
  appId: "1:340983379768:web:0d21e62dcac9ac73c073cd",
  measurementId: "G-G58WGS8XPC"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

function ScreenLogger() {
  let location = useLocation();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    analytics.setCurrentScreen(page_path);
    analytics.logEvent("screen_view", {
      app_name: 'AdAstraPerAmorem',
      screen_name: page_path,
    });
  }, [location]);
  return null;
}

ReactDOM.render(
  <BrowserRouter>
    <ScreenLogger/>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();