import React from 'react';
import '../App.css';
import {Typography} from '@material-ui/core';
import { Page } from '../components/Page';

export default function HomePage() {
  return (
    <Page title='Praktisk informasjon'>
      <Typography>
          Her vil det komme info om vielsen, festen, transportmuligheter, og hotell og overnattingsmuligheter på Voss.
      </Typography>
    </Page>
  );
}
