import React from 'react';
import '../App.css';
import { WeddingBasePage } from '../components/WeddingBasePage';
import front2 from '../images/wedding/front2cropped.png';
import bunad from '../images/wedding/bunad.jpg';
import vangskyrkja from '../images/wedding/vangskyrkja.jpg';
import fleischers from '../images/wedding/fleischers.jpg';
import wishlist from '../images/wedding/wishlist.png';
import { Box, Button, Container, Divider, Grid, Tooltip as MuiTooltip, Typography, withStyles} from '@material-ui/core';
import Image from 'material-ui-image';

function Kjøkemeister() {
  return (
    <span>
      <Typography variant="body2" component={'span'}>
        Kontakt kjøkemeister Ann Elin:<br/>
        E-post: <a href="mailto:annelinsolbu@gmail.com">annelinsolbu@gmail.com</a><br/>
        Telefon: <a href="tel:+4795975059">95975059</a>
      </Typography>
    </span>
  );
};

export default function WeddingPage() {
  const [showingKjøkemeister, setShowingKjøkemeister] = React.useState(false);
  const Tooltip = withStyles({
    tooltipPlacementBottom: {
      margin: "4px 0"
    }
  })(MuiTooltip);
  let contactButton = showingKjøkemeister ? (
    <Kjøkemeister />
  ) : (
    <Button variant="contained"
      onClick={() => setShowingKjøkemeister(true)}
    >
      <Typography variant="body2">
        Vis kontaktinfo for Ann Elin
      </Typography>
    </Button>
  );
  return (
    <WeddingBasePage title='Bryllup'>
      <Container maxWidth="md">
        <Box maxWidth="sm" mt={2}>
           <img src={front2} width='100%' alt=''/>
        </Box>
        <Typography align="center" variant="h4">Kjære familie og vener</Typography>
        <Divider/>
        <br/>
        <Grid container spacing={3} alignContent='center' justify='center'>
          <Grid item xs={12} sm={12}>
            <Typography align="center" variant="subtitle1">
              Me har gleda av å invitere til haustbryllup på Voss den 9. oktober 2021!
            </Typography>
          </Grid>
          <Grid item xs={8} sm={3}>
            <Tooltip title="Me gifter oss i bunad">
              <Image src={bunad} disableSpinner aspectRatio={(3/4)}/>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography display="inline">
              Denne sida inneheld litt praktisk informasjon for bryllupet vårt, og det vil
              kome meir informasjon her etter kvart. Har de spørsmål de ikkje finn svar på
              her må de gjerne ta kontakt.
              <br/>
              <br/>
              Me har valgt å feire den store dagen på Voss fordi me møttest på Voss
              Folkehøgskule skuleåret 09/10. Det er også hovudgrunnen til valg av dato,
              nemleg 09/10 eller 09. oktober. Då det i tillegg viste seg at 09/10-2021 havna
              på ein laurdag vart valget enkelt, sidan me i år også har vore kjærastar i 10 år.
              <br/>
              <br/>
              Då me i etterkant av trulovinga vår hausten 2019 oppdaga at alt dette klaffa,
              vart dato og plass valgt! Kathrine har i tillegg lenge ynskja å gifte seg i
              Hardangerbunaden ho har arva av mormora si, og bunad passer også bra på hausten
              når fargane er ekstra fine og temperaturen ikkje er for varm.
            </Typography>
          </Grid>
        </Grid>
        <br/>
        <Typography align="center" variant="h4" id="program">Program</Typography>
        <Divider/>
        <br/>
        <Grid container spacing={3} alignContent='center' justify='center'>
          <Grid item xs={8} sm={3}>
            <Tooltip title="Vangskyrkja på Voss">
              <Image src={vangskyrkja} disableSpinner aspectRatio={(3/4)} />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography>
              <Typography align="left" variant="h5">Fredag</Typography>
              Fleischer's Hotel har i utgangspunktet tilbudt oss ei "Historisk Vandring"
              på hotellet med femte generasjons vertskap, for dei som er interesserte.
              Det skal også vere mogleg å få sitte saman til middag, for dei som er der
              då.<br/>

              <Typography align="left" variant="h5">Laurdag</Typography>

              • Vigsel i Vangskyrkja kl 14:00<br/>
              • Apertif og "Vossatapas" på Fleischer's Hotel frå kl 15:30, medan me er ute og tar bilete.<br/>
              • Middag og fest på Fleischer's Hotel frå kl 17:00<br/>
              
              <Typography align="left" variant="h5">Sundag</Typography>

              Alle er velkomne til å hjelpe oss med kakerestar i Hagestuen sundag føremiddag.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Me kjem med meir utfyllande informasjon om fredag og sundag seinare, når me
              også veit meir om COVID-19 situasjonen.
              <br/>
              <br/>              

              <Typography align="left" variant="h5" id="innslag">Taler og innslag til festen</Typography>
              Kjøkemeister/toastmaster (eventuelt "smørbrødmester") for bryllupet vårt
              vert <b>Ann Elin</b>, storesøstera til Kathrine. Alle som ynskjer å halde
              tale eller bidra med andre innslag eller underhaldning under festen må ta
              kontakt med henne. De finn kontaktinformasjonen hennar her.
              <br/>
              <br/>
              {contactButton}
              <br/>
              <br/>
              Forhåpentlegvis kan me samlast alle til fest i oktober, og i det høve har me
              nokre anmodninger for dei "taletrengte":
              <br/>
              • Taler og innslag må meldast inn til kjøkemeister Ann Elin innan <b>1. oktober</b>.<br/>
              • Taler bør maksimalt vere på om lag <b>5 minutter</b>, fortrinnsvis rundt 3 minutter<br/>
              • Gje beskjed om eventuelle lydtekniske behov e.l. i god tid, så me får avklart
                dette med Fleischer's Hotel.<br/>
              • Me er absolutt opne for fleire taler og innslag ut over dei "obligatoriske"
                bryllupstalene, men dersom det vert mange må me sette begrensninger. Det kan
                vere fint om fleire går saman der det er naturleg.<br/>
              <br/>
              Til orientering vil det tilretteleggast for at ein kan reise seg og tale frå
              der ein sit, men det vil også vere mogleg å bruke "parkettscenen" i lokalet.
              Der står det òg eit flygelpiano, og elles vil mikrofon og anlegg med meir vere
              tilgjengelig for dei som ynskjer det.
              <br/>
              <br/>
              Igjen må me presisere at me ikkje veit korleis det vert med Covid-19 til
              hausten, og at me alle er ansvarlege for å overhalde gjeldande smittevernreglar,
              også under dei ulike innslaga.
            </Typography>
          </Grid>
        </Grid>
        <br/>
        <Typography align="center" variant="h4" id="praktisk">Praktisk informasjon</Typography>
        <Divider/>
        <br/>
        <Grid container spacing={3} alignContent='center' justify='center'>
          <Grid item xs={12} sm={12}>
            <Typography>
              Me gifter oss i bunad og ynskjer at andre som vil bruke bunad kan gjere det.
              Kleskoden er elles "pent"/mørk dress.
            </Typography>
          </Grid>
          <Grid item xs={10} sm={4}>
            <Tooltip title="Festen vil holdes på Fleischer's Hotel">
              <Image src={fleischers} disableSpinner aspectRatio={(4/3)}/>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>
              Me ynskjer svar på kven som kjem seinest innan <b>15. august</b>. Då vil me
              gjerne òg at de nemner <b>allergiar</b> eller liknande, så me kan informere
              kjøkkenet i god tid.
              <br/>
              <br/>
              <Typography variant="h5">Overnatting</Typography>

              Fleischer's Hotel tilbyr rabatterte prisar på overnatting for
              bryllupsgjestene våre. Ta kontakt på <a href="mailto:booking@fleischers.no">
              booking@fleischers.no</a> og vis til vårt bryllup. Dei oppfordrer til å
              bestille snarast mogleg, ettersom helga ligg midt mellom dei to
              haustferievekene, og har rom i mange ulike prisklassar og storleikar.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>
              Det finst også andre hotell, samt eit ungdomsherberge og ein campingplass
              i gangavstand frå Fleischer's Hotel.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">Transport</Typography>
            <Typography>
              Fleischer's Hotel ligg om lag 0 meter frå Voss Stasjon, for dei som reiser
              med tog. Togreisa frå Oslo S tar om lag 5t og 20min. Frå Bergen stasjon tar
              reisa om lag 1t og 20 min. Næraste flyplass vert Bergen lufthavn Flesland.
              <br/>
              <br/>
              <Typography variant="h5">Smittevern</Typography>
              Me håper at smittesituasjonen vert betre til hausten, men må ta forbehald
              om at endringer kan skje med tanke på antall gjester både i kyrkja og på
              festen. Lokalet me har bestilt rommar til vanleg 250 personar, langt
              fleire enn me inviterer.
              <br/>
              <br/>
              Hotellet har gjennomført fleire bryllup det siste året, og har god erfaring
              med smittevern. Dei har saman med De Historiske Hotel &amp; Spisesteder
              utvikla eigne rutiner for begrensing av smittespreiing. Lokala har god
              takhøgde, vert tilrettelagd for å ha god avstand mellom gjester ved borda,
              samt ha enkel tilgang på håndrens og munnbind ved behov.
            </Typography>
          </Grid>
        </Grid>
        <br/>
        <Typography align="center" variant="h4" id="praktisk">Gåveynskjer</Typography>
        <Divider/>
        <br/>
        <Grid container spacing={3} alignContent='center' justify='center'>
          <Grid item xs={10} sm={4}>
            <Image src={wishlist} disableSpinner aspectRatio={1} color={'transparent'}/>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>
                For oss er den styrsta gåva at de vil kome og feire med oss på Voss i
                oktober, så ta gjerne reisekostnader med i betraktninga når det gjeld gåver! 
                <br/>
                <br/>
                Me har det meste av det me treng, men i høve bryllupet har me funne eit 
                finservise hos C.I. Pedersen Glassmagasin som me godt kunne tenke oss.
                Det er eit servise frå Rosenthal kalla "Gropius TAC Skin Gold".
                <br/>
                <br/>
                Me har difor laga ei bryllupsliste for dette serviset, men sidan C.I. Pedersen 
                ikkje har eigen nettbutikk, har me laga ei eiga side 
                for gåveynskjer her på nettsida vår. Der kan de sjå bileter av dei ulike delene 
                saman med namn, varenummer og pris frå lista hos C.I. Pedersen. Trykk gjerne på 
                bileta for å sjå dei litt større. Meir informasjon om bestilling og antall finn de
                {' '}<a href="/onskeliste">her</a> eller i menyen under
                {' '}<a href="/onskeliste">Gåveynskjer</a>.
            </Typography>
          </Grid>
        </Grid>
        <br/>
        <br/>
      </Container>
    </WeddingBasePage>
  );
}
